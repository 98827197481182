<template>
	<!-- loader -->
	<div
		class="d-flex justify-center align-center py-16"
		v-if="loading">
		<v-progress-circular
			indeterminate
			color="primary"></v-progress-circular>
	</div>

	<!-- main page -->
	<div
		v-else
		class="home">
		<v-row no-gutters>
			<v-col
				:order="$vuetify.breakpoint.smAndUp ? 3 : 1"
				:cols="12"
				:sm="6">
				<div class="d-flex justify-end">
					<!-- refresh -->
					<v-tooltip
						bottom
						:open-delay="1000">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-bind="attrs"
								v-on="on"
								icon
								@click="refresh">
								<v-icon> mdi-refresh </v-icon>
								<!-- تحديث -->
							</v-btn>
						</template>
						<span> تحديث (alt+R)</span>
					</v-tooltip>

					<!-- logout -->
					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								text
								v-bind="attrs"
								v-on="on">
								<v-icon left> mdi-account-circle </v-icon>
								اهلا بك {{ activeUser.FullName }}
							</v-btn>
						</template>
						<v-list dense>
							<template v-if="false">
								<v-list-item
									@click="login(user)"
									v-for="(user, i) in users"
									:class="{
										'primary--text':
											user.UserName === activeUser.UserName &&
											user.Password === activeUser.Password &&
											user.CenterId === activeUser.CenterId,
									}"
									:key="i">
									<v-list-item-title>
										{{ user.UserName }}
										-
										{{ getCenter(user.CenterId).Name }}
									</v-list-item-title>
								</v-list-item>
								<v-divider class="my-1"></v-divider>
								<v-list-item :to="{ name: 'login' }">
									<v-list-item-title>إضافة حساب</v-list-item-title>
								</v-list-item>
							</template>
							<v-list-item @click="openLogoutDialog = true">
								<v-list-item-title>تسجيل الخروج</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
				</div>
			</v-col>
			<v-col
				:order="2"
				:cols="12"
				:sm="6">
				<!-- tabs -->
				<v-tabs
					v-if="!$fromEdunix"
					v-model="tab"
					show-arrows
					color="myOrange"
					background-color="transparent"
					class="tabs"
					center-active>
					<v-tab
						class="font-weight-bold"
						:to="{ name: 'oneMessage' }">
						رسالة لطالب
					</v-tab>
					<v-tab
						class="font-weight-bold"
						:to="{ name: 'broadcast' }">
						رسالة جماعية
					</v-tab>
				</v-tabs>
			</v-col>
		</v-row>

		<v-divider class="mb-3"></v-divider>

		<router-view ref="child"></router-view>

		<!-- logout -->
		<v-dialog
			v-model="openLogoutDialog"
			width="400">
			<v-card
				class="pa-6"
				rounded="lg">
				<p class="text-center mb-8 text-h6">هل تريد تسجيل الخروج؟</p>
				<v-row>
					<v-col cols="6">
						<v-btn
							color="primary"
							block
							@click="openLogoutDialog = false">
							إلغاء
						</v-btn>
					</v-col>
					<v-col cols="6">
						<v-btn
							color="primary"
							text
							block
							@click="logout(activeUser)">
							تاكيد
						</v-btn>
					</v-col>
				</v-row>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
	name: 'Home',

	data() {
		return {
			//for all
			loading: true,

			tab: null,
			openLogoutDialog: false,
		};
	},

	computed: {
		...mapState({
			users: (state) => state.login.users,
			activeUser: (state) => state.login.activeUser,
		}),

		...mapGetters({
			getCenter: 'login/getCenter',
		}),
	},

	methods: {
		// for all
		logout(user) {
			this.$store.dispatch('login/logout', { user: user });
			this.$router.replace({ name: 'login' });
			this.$eventBus.$emit('show-snackbar', false, 'تم تسجيل الخروج');
		},
		login(user) {
			if (
				user.UserName === this.activeUser.UserName &&
				user.Password === this.activeUser.Password &&
				user.CenterId === this.activeUser.CenterId
			)
				return;
			this.$eventBus.$emit('loader', true);
			this.$store
				.dispatch('login/login', {
					userName: user.UserName,
					password: user.Password,
					centerId: user.CenterId,
				})
				.then(() => {
					this.$eventBus.$emit('loader', false);
					this.$eventBus.$emit('show-snackbar', false, 'تم تبديل المستخدم');
				});
		},
		fetch() {
			this.loading = true;
			Promise.all([
				this.$store.dispatch('message/fetchSpecialties'),
				this.$store.dispatch('message/fetchOldMessages', {
					unReadOnly: true,
					pageNumber: 1,
				}),
			])
				.then(() => {})
				.catch(() => {})
				.finally(() => {
					this.loading = false;
				});
		},
		fetchBrodcastMessages() {
			this.loadingBrodcast = true;
			this.$store
				.dispatch('message/fetchAllBrodcast')
				.then(() => {})
				.catch(() => {})
				.finally(() => {
					this.loadingBrodcast = false;
				});
		},

		refresh() {
			this.$refs.child.refresh();
		},
	},

	mounted() {
		// this.$store.dispatch('clearAfterLogout');
		this.fetch();
		this.fetchBrodcastMessages();
	},

	created() {
		window.addEventListener('keydown', (e) => {
			if (e.altKey && e.code === 'KeyR') {
				e.preventDefault();
				this.refresh();
			}
		});
	},
};
</script>

<style>
::-webkit-scrollbar {
	width: 7px;
}
::-webkit-scrollbar-thumb {
	background: #888;
}
::-webkit-scrollbar-track {
	background: #f1f1f1;
}
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
.home * {
	scrollbar-width: thin;
}
.student-cell {
	cursor: pointer;
	border: 1px solid #ccc;
}
.student-cell:hover {
	background-color: #eee;
}
.send-icon {
	transform: scale(-1);
}
.page-part {
	width: 50%;
}
@media (max-width: 960px) {
	.page-part {
		width: 100% !important;
	}
}
.v-data-table th,
.v-data-table td {
	padding: 0 9px !important;
}
@media (min-width: 960px) {
	.col-md-auto {
		flex: 1;
	}
}
</style>
