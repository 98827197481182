export function diffDate(first, sec = new Date()) {
	// deffrent time in days between two times where the secound parameter is the previously time
	return Math.floor((first - sec) / 86400000);
}

export function dateFormater(date) {
	const trueDate = new Date(date.getTime() + 1000 * 60 * 60 * 3);
	return new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'short' }).format(trueDate);
}
export function dateFormaterJustDate(date) {
	return new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short' }).format(date);
}

export function getDay(date) {
	return new Intl.DateTimeFormat('ar-SY', { weekday: 'long' }).format(date);
}

export function getMonth(date) {
	return new Intl.DateTimeFormat('ar-SY', { month: 'long' }).format(date);
}
