import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import BroadcastView from '../views/BroadcastView.vue';
import OneMessageView from '../views/OneMessageView.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView,
		redirect: { name: 'oneMessage' },
		children: [
			{
				path: '/broadcast',
				name: 'broadcast',
				component: BroadcastView,
			},
			{
				path: '/one-message',
				name: 'oneMessage',
				component: OneMessageView,
			},
		],
	},
	{
		path: '/login',
		name: 'login',
		component: LoginView,
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
