<template>
	<div>
		<div
			class="d-md-flex d-block"
			ref="container">
			<div
				class="page-part"
				ref="filters">
				<!-- filter form -->
				<v-sheet class="white mb-4">
					<v-row
						class="ma-0 align-center mb-2"
						dense>
						<!-- class -->
						<v-col
							cols="12"
							sm="6">
							<v-select
								v-model="selectedSpecialties"
								:items="[{ SpecialtyName: 'كل الصفوف', SpecialtyId: 'ALL' }, ...specialties]"
								:disabled="loadingSendingBrodcast"
								item-text="SpecialtyName"
								item-value="SpecialtyId"
								return-object
								@change="selectedCourse = null"
								label="الصف"
								outlined
								dense
								hide-details
								clearable
								:menu-props="{ offsetY: true }"
								class="rounded-lg">
							</v-select>
						</v-col>

						<!-- subClass -->
						<v-col
							cols="12"
							sm="6">
							<v-select
								v-model="selectedCourse"
								:items="[{ CourseName: 'كل الشعب', Id: 'ALL' }, ...courses]"
								:disabled="
									!selectedSpecialties ||
									selectedSpecialties?.SpecialtyId === 'ALL' ||
									loadingSendingBrodcast
								"
								@change="fetchStudentByCourse"
								item-text="CourseName"
								item-value="Id"
								return-object
								label="الشعبة"
								outlined
								dense
								hide-details
								clearable
								:menu-props="{ offsetY: true }"
								class="rounded-lg">
							</v-select>
						</v-col>
					</v-row>

					<div class="mb-3">
						<v-select
							:disabled="
								!students.length ||
								!selectedCourse ||
								selectedCourse.Id === 'ALL' ||
								loadingSendingBrodcast ||
								loadingStudents
							"
							:loading="loadingStudents"
							v-model="selectedStudent"
							:items="students"
							item-text="Account.FullName"
							item-value="Id"
							label="الطلاب"
							multiple
							outlined
							dense
							hide-details
							:menu-props="{ offsetY: true }"
							class="rounded-lg">
							<template v-slot:prepend-item>
								<v-list-item
									ripple
									@mousedown.prevent
									@click="toggleAllStudent">
									<v-list-item-action>
										<v-icon :color="selectedStudent.length > 0 ? 'indigo darken-4' : ''">
											{{ icon }}
										</v-icon>
									</v-list-item-action>
									<v-list-item-content>
										<v-list-item-title> كل الطلاب </v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-divider class="mt-2"></v-divider>
							</template>
							<template v-slot:selection="{ item, index }">
								<span v-if="index === 0 && selectedStudent.length === students.length">
									لكل الطلاب
								</span>
								<span v-if="selectedStudent.length !== students.length">
									{{ item.Account.FullName }} ,
								</span>
							</template>
						</v-select>
					</div>
					<v-divider class="my-2"></v-divider>
					<!-- message -->
					<v-textarea
						v-model="brodcastMessage"
						:disabled="loadingSendingBrodcast"
						label="الرسالة"
						outlined
						dense
						hide-details
						rows="4"
						auto-grow
						no-resize
						class="rounded-lg mb-3"></v-textarea>

					<!-- send btn -->
					<v-btn
						:disabled="
							Boolean(
								(selectedSpecialties?.SpecialtyId !== 'ALL' && !selectedCourse) ||
									!brodcastMessage ||
									(selectedSpecialties?.SpecialtyId !== 'ALL' &&
										selectedCourse?.Id !== 'ALL' &&
										!selectedStudent.length),
							)
						"
						:loading="loadingSendingBrodcast"
						x-large
						color="primary"
						@click="sendBrodcast">
						إرسال
						<v-icon
							right
							class="send-icon">
							mdi-send-variant
						</v-icon>
					</v-btn>
				</v-sheet>
			</div>

			<v-divider
				vertical
				ref="divider"
				class="px-2 mx-2 d-none d-md-block"
				style="cursor: col-resize"></v-divider>

			<!-- all messages -->
			<div
				class="page-part mt-4 mt-md-0"
				ref="chat">
				<v-sheet
					v-if="brodcastMessages.length"
					class="transparent">
					<h2 class="ma-2">الرسائل الجماعية المرسلة</h2>
					<v-card class="rounded-xl elevation-1 overflow-hidden">
						<v-data-table
							:items="loadingBrodcast ? [] : brodcastMessages"
							:headers="brodcastHeader"
							@click:row="showBrodcast"
							:loading="loadingBrodcast"
							:loading-text="'انتظر قليلا...'"
							:no-data-text="'لاتوجد رسائل'"
							:items-per-page="-1"
							hide-default-footer
							mobile-breakpoint="0"
							fixed-header
							height="calc(100vh - 180px)">
							<!-- slice the message -->
							<template v-slot:item.Content="{ item }">
								{{ item.Content?.slice(0, 70) }}
								<span v-if="item.Content?.length > 70"> ... </span>
							</template>

							<template v-slot:item.CourseName="{ item }">
								{{
									item.AllSchool
										? 'لكل المدرسة'
										: item.SpecialtyName +
										  ' - ' +
										  (!!item.CourseName ? item.CourseName : 'كل الشعب')
								}}
							</template>

							<template v-slot:item.SentAt="{ item }">
								{{ dateFormaterJustDate(new Date(item.SentAt)) }}
							</template>

							<template v-slot:item.actions="{ item }">
								<div class="d-flex align-center">
									<v-tooltip top>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												@click.stop="startEditing(item)"
												:disabled="!!(loadingDelete || loadingUpdate)"
												class="ml-2"
												color="myBlueBg"
												x-small
												fab
												elevation="0"
												v-bind="attrs"
												v-on="on">
												<v-icon
													small
													color="myBlue">
													mdi-pencil
												</v-icon>
											</v-btn>
										</template>
										<span>تعديل</span>
									</v-tooltip>
									<v-tooltip top>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												@click.stop="deleteMessage(item)"
												:loading="loadingDelete === item.Id"
												:disabled="!!(loadingDelete || loadingUpdate)"
												color="myOrangeBg"
												x-small
												fab
												elevation="0"
												v-bind="attrs"
												v-on="on">
												<v-icon
													small
													color="myOrange">
													mdi-delete-outline
												</v-icon>
											</v-btn>
										</template>
										<span>حذف</span>
									</v-tooltip>
								</div>
							</template>
						</v-data-table>
					</v-card>
				</v-sheet>
			</div>
		</div>

		<!-- brodcast message -->
		<v-dialog
			v-model="showMessage"
			@click:outside="editing = false"
			width="500">
			<v-card
				class="pa-6"
				rounded="lg">
				<template v-if="messageToShow">
					<div class="d-flex justify-space-between">
						<div>
							<div class="text-caption">
								{{ dateFormaterJustDate(new Date(messageToShow.SentAt)) }}
							</div>
							<div class="mb-3 font-weight-bold">
								الى:
								<span v-if="messageToShow.AllSchool"> لكل المدرسة </span>
								<span v-else>
									{{ messageToShow.SpecialtyName }} -
									{{ messageToShow.CourseName ? messageToShow.CourseName : 'كل الشعب' }}
								</span>
							</div>
						</div>
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									@click="copy"
									icon
									color="grey lighten-1"
									x-small
									v-bind="attrs"
									v-on="on">
									<v-icon>mdi-content-copy</v-icon>
								</v-btn>
							</template>
							<span>نسخ</span>
						</v-tooltip>
					</div>
					<div v-html="finMessage(messageToShow.Content)"></div>

					<!-- textarea -->
					<template v-if="editing">
						<div class="mb-2">
							<v-textarea
								autofocus
								v-model="updateContent"
								outlined
								dense
								hide-details
								:rows="3"
								no-resize
								auto-grow
								class="rounded-lg"></v-textarea>
						</div>

						<!-- send button -->
						<div>
							<v-btn
								:disabled="loadingUpdate || !updateContent"
								:loading="loadingUpdate"
								@click="updateMessage"
								class="py-5"
								small
								color="primary">
								تعديل
								<v-icon right> mdi-pencil </v-icon>
							</v-btn>
						</div>
					</template>
				</template>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { dateFormaterJustDate } from '@/helpers/helpers';

export default {
	name: 'BroadcastView',

	data() {
		return {
			loadingBrodcast: false,
			loadingSendingBrodcast: false,

			selectedSpecialties: null,
			selectedCourse: null,
			brodcastToSchool: false,
			brodcastMessage: '',
			brodcastHeader: [
				{ text: 'الى', value: 'CourseName', width: '25%' },
				{ text: 'التاريخ', value: 'SentAt', width: '25%' },
				{
					text: 'الرسالة',
					value: 'Content',
				},
				{
					text: '',
					value: 'actions',
				},
			],

			messageToShow: null,
			showMessage: false,

			dialog: false,

			editing: false,
			updateContent: '',
			loadingUpdate: false,
			loadingDelete: false,

			loadingStudents: false,
			students: [],
			selectedStudent: [],

			urlReg: /((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/\S*)?)/gim,
		};
	},

	computed: {
		icon() {
			if (this.selectedStudent.length === this.students.length) return 'mdi-close-box';
			if (this.selectedStudent.length) return 'mdi-minus-box';
			return 'mdi-checkbox-blank-outline';
		},
		courses() {
			return this.selectedSpecialties && this.selectedSpecialties.SpecialtyId !== 'ALL'
				? this.selectedSpecialties.Courses
				: [];
		},
		...mapState({
			specialties: (state) => state.message.specialties,
			brodcastMessages: (state) => state.message.brodcastMessages,
		}),
	},

	methods: {
		dateFormaterJustDate,
		initDragging() {
			if (!this.$refs.divider) return;
			let isDragging = false;
			this.$refs.divider.$el.addEventListener('mousedown', (e) => {
				isDragging = true;
				this.$refs.divider.$el.style.borderColor = '#0f4d96';
			});
			this.$refs.container.addEventListener('mousemove', (e) => {
				if (!isDragging) return;
				const containerRect = this.$refs.container.getBoundingClientRect();
				const x = e.clientX - containerRect.left;
				const containerWidth = containerRect.width;
				const draggableWidth = this.$refs.divider.$el.offsetWidth;
				const leftCol = this.$refs.chat;
				const rightCol = this.$refs.filters;
				const leftColWidth = x - draggableWidth / 2;
				const rightColWidth = containerWidth - x - draggableWidth / 2;
				leftCol.style.width = `${leftColWidth}px`;
				rightCol.style.width = `${rightColWidth}px`;
			});
			this.$refs.container.addEventListener('mouseup', (e) => {
				isDragging = false;
				this.$refs.divider.$el.style.borderColor = 'rgba(0, 0, 0, 0.12)';
			});
		},

		sendBrodcast() {
			this.loadingSendingBrodcast = true;

			let fetchVar =
				this.selectedSpecialties.SpecialtyId === 'ALL'
					? this.$store.dispatch('message/sendBrodcasetMessage', {
							Content: this.brodcastMessage,
							AllSchool: true,
							CourseId: null,
							SpecialtyId: null,
					  })
					: this.selectedStudent.length === this.students.length
					? this.$store.dispatch('message/sendBrodcasetMessage', {
							Content: this.brodcastMessage,
							CourseId: this.selectedCourse?.Id === 'ALL' ? null : this.selectedCourse.Id,
							SpecialtyId: this.selectedSpecialties.SpecialtyId,
					  })
					: this.$store.dispatch('message/sendBrodcasetMessage', {
							Content: this.brodcastMessage,
							CourseId: this.selectedCourse.Id,
							SpecialtyId: this.selectedSpecialties.SpecialtyId,
							StudentsIds: this.selectedStudent,
					  });

			fetchVar
				.then(() => {
					this.$eventBus.$emit('show-snackbar', false, 'تم إرسال الرسالة');
					this.fetchBrodcastMessages();
					this.brodcastMessage = '';
				})
				.catch(() => {
					this.$eventBus.$emit('show-snackbar', true, 'فشل الإرسال');
				})
				.finally(() => {
					this.loadingSendingBrodcast = false;
				});
		},
		fetchBrodcastMessages() {
			this.loadingBrodcast = true;
			this.editing = false;
			this.updateContent = '';
			this.showMessage = false;

			this.loadingUpdate = false;
			this.loadingDelete = false;
			this.$store
				.dispatch('message/fetchAllBrodcast')
				.then(() => {})
				.catch(() => {})
				.finally(() => {
					this.loadingBrodcast = false;
				});
		},

		showBrodcast(message) {
			this.messageToShow = message;
			this.showMessage = true;
		},
		startEditing(message) {
			this.messageToShow = message;
			this.showMessage = true;
			this.editing = true;
			this.updateContent = this.messageToShow.Content;
		},
		updateMessage() {
			this.loadingUpdate = true;
			this.$store
				.dispatch('message/editBroadcast', {
					Content: this.updateContent,
					messageId: this.messageToShow.Id,
				})
				.then(() => {
					this.fetchBrodcastMessages();
				});
		},
		deleteMessage(message) {
			this.loadingDelete = message.Id;
			this.$store
				.dispatch('message/deleteBroadcast', {
					groupMessageId: message.Id,
				})
				.then(() => {
					this.fetchBrodcastMessages();
				});
		},
		copy() {
			navigator.clipboard.writeText(this.messageToShow.Content);
		},
		finMessage(message) {
			if (!message) return '-';
			return message
				.replace(this.urlReg, (a, b) => {
					let link = b;
					/^(www\.)/.test(b) && (link = 'https://' + b);

					!/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)/.test(link) &&
						(link = 'https://www.' + b);

					return `<a href='${link}' target='_blank'>${b}</a>`;
				})
				.replaceAll('\n', '<br/>');
		},

		fetchStudentByCourse() {
			this.students = [];
			this.selectedStudent = [];

			if (!this.selectedCourse || this.selectedCourse.Id === 'ALL') return;

			this.loadingStudents = true;
			this.$store
				.dispatch('message/fetchStudents', {
					courseId: this.selectedCourse.Id,
				})
				.then((res) => {
					this.students = res;
					this.selectedStudent = this.students.map((e) => e.Id);
				})
				.finally(() => {
					this.loadingStudents = false;
				});
		},
		toggleAllStudent() {
			this.$nextTick(() => {
				if (this.selectedStudent.length === this.students.length) {
					this.selectedStudent = [];
				} else {
					this.selectedStudent = this.students.map((e) => e.Id);
				}
			});
		},

		refresh() {
			this.fetchBrodcastMessages();
		},
	},

	mounted() {
		this.initDragging();
	},
};
</script>
