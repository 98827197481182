<template>
	<div
		style="height: 80vh; display: flex; align-items: center; justify-content: center"
		v-if="loading && $route.name !== 'login'">
		<v-progress-circular indeterminate> </v-progress-circular>
	</div>
	<v-app v-else>
		<v-main
			app
			class="white">
			<div class="white py-3 px-5">
				<router-view />
			</div>
		</v-main>
		<v-snackbar
			text
			shaped
			v-model="showSnackbar"
			:color="isError ? 'error' : 'myGreen'">
			<div class="text-center">
				{{ message }}
			</div>
		</v-snackbar>
	</v-app>
</template>

<script>
export default {
	name: 'App',
	data() {
		return {
			message: '',
			isError: true,
			showSnackbar: false,

			loading: true,
		};
	},
	created() {
		this.$eventBus.$on('show-snackbar', (isError, message) => {
			this.message = message;
			this.isError = isError;
			this.showSnackbar = true;
		});
		this.$eventBus.$on('loader', (status) => {
			this.loading = status;
		});
	},
};
</script>

<style lang="scss">
@font-face {
	font-family: Fairuz;
	src: url('./assets/Fairuz/Fairuz-Light.otf');
	font-weight: 100 300;
}
@font-face {
	font-family: Fairuz;
	src: url('./assets/Fairuz/Fairuz-Normal.otf');
	font-weight: 400;
}
@font-face {
	font-family: Fairuz;
	src: url('./assets/Fairuz/Fairuz-Bold.otf');
	font-weight: 500 700;
}
@font-face {
	font-family: Fairuz;
	src: url('./assets/Fairuz/Fairuz-Black.otf');
	font-weight: 800 900;
}

$table-color-1: #f5f8ff;
$table-color-2: #e9f3ff;

body {
	background-color: #fbfbfb;
}
.v-data-table {
	.v-data-table-header {
		tr {
			background: white !important;
		}
	}
	tr:nth-child(odd) {
		background: $table-color-1;
	}
	tr:nth-child(even) {
		background: $table-color-2;
	}
}
</style>
