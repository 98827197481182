import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    rtl: true,
    theme: {
        themes: {
            light: {
                white: '#fbfbfb',
                primary: '#0f4d96',
                secondary: '#fb5d33',
                accent: '#8c9eff',
                error: '#b71c1c',
                myOrange: '#fb5d33',
                myOrangeBg: '#f5e2dc',
                myGreen: '#2cac3e',
                myGreenBg: '#d1ecd4',
                myBlue: '#5da2ff',
                myBlueBg: '#d3e5ff',
            },
        },
    },
});
