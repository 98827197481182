var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex align-center pb-2",staticStyle:{"position":"relative"}},[_c('h1',[_vm._v(" "+_vm._s(_vm.student.Name)+" ")]),_c('v-spacer'),(_vm.unReadMessages.length)?_c('v-btn',{attrs:{"disabled":_vm.loadingMarksAsRead,"loading":_vm.loadingMarksAsRead,"color":"primary"},on:{"click":_vm.markAsRead}},[_c('span',[_vm._v(" تحدد كمقروء ")])]):_vm._e(),_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.showSearch = !_vm.showSearch;
				_vm.searchText = null;}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-magnify ")])]}}])},[_c('span',[_vm._v("بحث (ctrl+f)")])])],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSearch),expression:"showSearch"}],staticClass:"pa-1 pb-0 white",staticStyle:{"position":"absolute","top":"100%","right":"0","left":"0","z-index":"2"},attrs:{"transition":"scroll-x-transition"}},[_c('v-text-field',{staticClass:"mb-2",attrs:{"autofocus":"","label":"بحث","dense":"","outlined":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.showSearch = false}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('v-divider')],1)])],1),_c('v-divider'),_c('div',{staticClass:"messages d-flex flex-column-reverse align-center"},[_c('div',{staticClass:"d-flex align-end",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"width":"100%"}},[(_vm.loading)?[_c('div',{staticClass:"d-flex justify-center align-center py-16"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)]:[_c('div',{staticClass:"d-flex flex-column"},[_vm._l((_vm.messages.filter((e) => {
								return _vm.searchText ? e.Content.includes(_vm.searchText) : true;
							})),function(message,i){return _c('div',{key:i + 'old',staticClass:"px-0 my-3"},[_c('massage',{attrs:{"message":message,"sender":_vm.student.Name},on:{"refresh":_vm.hardFetchMessage,"updateMessage":_vm.startUpdating}})],1)}),_vm._l((_vm.newMessages.filter((e) => {
								return _vm.searchText ? e.Content.includes(_vm.searchText) : true;
							})),function(message,i){return _c('div',{key:i + 'new' + message.isFail,staticClass:"px-0 my-3"},[_c('massage',{attrs:{"disableAction":true,"message":message,"sender":_vm.student.Name},on:{"re-send":function($event){return _vm.reSend(i)}}})],1)})],2),(
							!_vm.messages.filter((e) => {
								return _vm.searchText ? e.Content.includes(_vm.searchText) : true;
							}).length &&
							!_vm.newMessages.filter((e) => {
								return _vm.searchText ? e.Content.includes(_vm.searchText) : true;
							}).length
						)?_c('div',{staticClass:"text-h5 text--secondary text-center",staticStyle:{"margin-bottom":"30vh"}},[(_vm.searchText)?_c('span',[_vm._v("لا يوجد رسالة مطابقة")]):_c('span',[_vm._v(" ما من رسائل بعد.. ")])]):_vm._e()],_c('div',{staticClass:"messages-input white px-1"},[_c('div',{staticClass:"d-flex pt-2"},[_c('div',{staticClass:"ml-2 massage-input"},[_c('v-textarea',{staticClass:"rounded-lg",attrs:{"autofocus":"","label":"الرسالة","outlined":"","dense":"","hide-details":"","rows":3,"no-resize":"","auto-grow":""},model:{value:(_vm.messageText),callback:function ($$v) {_vm.messageText=$$v},expression:"messageText"}})],1),_c('div',[(!_vm.updating)?_c('v-btn',{staticClass:"py-6",attrs:{"disabled":!_vm.messageText,"color":"primary"},on:{"click":_vm.sendMessage}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',[_vm._v(" إرسال ")]):_vm._e(),_c('v-icon',{staticClass:"send-icon",attrs:{"right":""}},[_vm._v(" mdi-send-variant ")])],1):_c('v-btn',{staticClass:"py-6",attrs:{"disabled":_vm.loadingUpdate || !_vm.messageText,"loading":_vm.loadingUpdate,"color":"primary"},on:{"click":_vm.updateMessage}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',[_vm._v(" تعديل ")]):_vm._e(),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-pencil ")])],1)],1)])])],2)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }