<template>
	<v-card
		v-if="!centerSelected"
		rounded="lg"
		elevation="lg"
		class="ma-auto my-10 pa-9"
		max-width="500">
		<div class="text-center">
			<h1 class="mb-6">اختر الفرع</h1>

			<v-select
				:disabled="loadingPage"
				:loading="loadingPage"
				:items="centers"
				v-model="selectedCenterId"
				item-text="Name"
				item-value="Id"
				label="اختر الفرع"
				class="mb-5"></v-select>
			<v-btn
				:disabled="selectedCenterId === null"
				@click="centerId = selectedCenterId"
				color="primary">
				اختيار
			</v-btn>
		</div>
	</v-card>
	<v-card
		v-else
		rounded="lg"
		elevation="lg"
		class="ma-auto my-10 pa-9"
		max-width="500">
		<div class="text-center">
			<h1 class="mb-6">تسجيل الدخول</h1>
			<v-form
				ref="form"
				@submit.prevent="submit"
				v-model="valid"
				lazy-validation>
				<v-text-field
					v-model="userName"
					name="name"
					label="اسم المستخدم"
					type="text"
					:rules="rules.required"></v-text-field>
				<v-text-field
					v-model="password"
					name="name"
					label="كلمة السر"
					type="password"
					:rules="rules.required"
					class="mb-5"></v-text-field>
				<v-btn
					:disabled="loading"
					:loading="loading"
					type="submit"
					color="primary"
					class="d-block mx-auto mb-3">
					تسجيل الدخول
				</v-btn>
				<!-- to change (hide) -->
				<v-btn
					v-if="centers.length > 1"
					text
					small
					@click="
						centerId = null;
						selectedCenterId = null;
					"
					class="d-block mx-auto">
					تغير المركز
				</v-btn>
			</v-form>
		</div>

		<v-btn
			v-if="activeUser"
			:to="{ name: 'home' }"
			exact
			icon
			absolute
			top
			right>
			<v-icon>mdi-arrow-right</v-icon>
		</v-btn>
	</v-card>
</template>

<script>
import { MD5 } from 'crypto-js';
import rules from '@/helpers/validation rules';
import { mapState } from 'vuex';

export default {
	name: 'LoginView',
	data() {
		return {
			userName: null,
			password: null,
			centerId: null, // to change (0)
			selectedCenterId: null, // to change (true)

			loading: false,
			loadingPage: true, // to change (false)
			valid: true,
			rules,
		};
	},

	computed: {
		centerSelected() {
			return typeof this.centerId === 'number';
		},
		...mapState({
			centers: (state) => state.login.centers,
			activeUser: (state) => state.login.activeUser,
		}),
	},

	methods: {
		MD5,
		submit() {
			if (!this.$refs.form.validate()) {
				this.$eventBus.$emit('show-snackbar', true, 'يرجى إدخال اسم المستخدم وكلمة السر.');
				return;
			}

			this.loading = true;
			this.$store
				.dispatch('login/login', {
					userName: this.userName,
					password: this.MD5(this.password).toString(),
					centerId: this.centerId,
				})
				.then(() => {
					this.$eventBus.$emit('loader', false);
					this.$eventBus.$emit('show-snackbar', false, 'اهلا بك ' + this.userName);
					this.$router.replace({ name: 'home' });
				})
				.catch(() => {
					this.$eventBus.$emit('show-snackbar', true, 'حدث خطأ ما');
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},

	// to change (delete)
	created() {
		this.loadingPage = true;
		this.$store.dispatch('login/fetchCenters').then((res) => {
			if (this.centers.length === 1) this.centerId = res[0].Id;
			this.loadingPage = false;
		});
	},
};
</script>
