import * as types from '../mutations';

export default {
	namespaced: true,

	state: {
		centerId: null,
		users: JSON.parse(localStorage.getItem('auth-users')) || [],
		activeUser: JSON.parse(localStorage.getItem('auth-active-user')) || null,

		centers: null,
	},

	actions: {
		login({ commit }, { userName, centerId, password }) {
			return window.axios
				.post(`/center/${centerId}/user/${userName}/login`, null, {
					headers: {
						'X-Password': password,
					},
				})
				.then((res) => {
					commit(types.LOGIN, { ...res.data, Password: password });
					return res.data;
				})
				.catch((err) => {
					throw err;
				});
		},
		logout({ commit }, { user }) {
			commit(types.LOGOUT, user);
		},

		fetchCenters({ commit }) {
			return window.axios.get(`/centers`).then((res) => {
				commit(types.CENTERS, res.data);
				return res.data;
			});
		},
	},

	mutations: {
		[types.LOGIN](state, user) {
			console.log('loging in...');

			state.activeUser = user;
			state.centerId = user.centerId;

			// const oldUserIndex = state.users.findIndex(
			//     (e) => e.userName === user.userName &&
			//         e.Password === user.Password &&
			//         e.CenterId === user.CenterId
			// );
			// if (oldUserIndex !== -1) {
			//     state.users[oldUserIndex] = user;
			// } else {
			//     state.users.push(user);
			// }

			window.axios.defaults.headers['X-Password'] = user.Password;

			localStorage.setItem('auth-active-user', JSON.stringify(state.activeUser));
			// localStorage.setItem('auth-users', JSON.stringify(state.users));
		},

		[types.LOGOUT](state, user) {
			console.log('loging out...');

			state.activeUser = null;
			state.centerId = null;

			delete window.axios.defaults.headers['X-Password'];

			// const userIndex = state.users.findIndex((e) => {
			//     return (
			//         e.UserName === user.UserName &&
			//         e.Password === user.Password &&
			//         e.CenterId === user.CenterId
			//     );
			// });
			// if (userIndex === -1) return;

			// state.users.splice(userIndex, 1);

			// localStorage.setItem('auth-users', JSON.stringify(state.users));
			localStorage.setItem('auth-active-user', JSON.stringify(null));
		},

		[types.CENTERS](state, centers) {
			state.centers = centers;
		},
	},

	getters: {
		getCenter: (state) => (id) => state.centers.find((e) => e.Id === id),
	},
};
