<template>
	<div>
		<div
			class="d-md-flex d-block"
			ref="container">
			<!-- filter form and student table -->
			<div
				class="page-part"
				ref="filters">
				<!-- filter form -->
				<v-row
					dense
					align="center"
					class="mb-3">
					<!-- start new chat -->
					<v-col cols="auto">
						<v-tooltip
							top
							:open-delay="1000">
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									v-bind="attrs"
									v-on="on"
									icon
									color="primary"
									@click="
										dialog = true;
										showSearchedNameTable = false;
										searchStudentName = '';
									">
									<v-icon> mdi-plus </v-icon>
								</v-btn>
							</template>
							<span>بدء محادثة جديدة (alt+N)</span>
						</v-tooltip>
					</v-col>

					<!-- name input -->
					<v-col
						cols="auto"
						class="flex-grow-1">
						<v-text-field
							autofocus
							v-model="searchStudentName"
							label="اسم الطالب"
							outlined
							dense
							hide-details
							class="rounded-lg"
							clearable
							@keydown.enter="searchStudentByName"
							@click:clear="showSearchedNameTable = false"></v-text-field>
					</v-col>

					<!-- search button -->
					<v-col cols="auto">
						<v-btn
							:disabled="loadingSearchStudent"
							:loading="loadingSearchStudent"
							block
							color="primary"
							@click="searchStudentByName">
							<v-icon left> mdi-magnify </v-icon>
							بحث
						</v-btn>
					</v-col>

					<v-divider
						vertical
						class="mx-2"></v-divider>

					<!-- change message to show -->
					<v-col cols="auto">
						<v-checkbox
							class="mt-0"
							:disabled="loadingOldMessages || !!searchStudentName"
							dense
							hide-details
							@change="whatMessagesToShow"
							v-model="unReadOnly"
							label="غير المقروءة"></v-checkbox>
					</v-col>
				</v-row>

				<!-- students table -->
				<v-sheet class="rounded-xl elevation-1 overflow-hidden">
					<v-data-table
						v-if="!showSearchedNameTable"
						:headers="oldMessagesHeader"
						:items="loadingOldMessages ? [] : oldMessages?.Messages"
						@click:row="openMessageFromOld"
						:item-class="studentTableClass"
						:loading="loadingOldMessages"
						:loading-text="'انتظر قليلا...'"
						:no-data-text="unReadOnly ? 'لاتوجد رسائل غير مقروءة' : 'لاتوجد رسائل'"
						:items-per-page="-1"
						hide-default-footer
						mobile-breakpoint="0"
						fixed-header
						height="calc(100vh - 180px)">
						<!-- student name -->
						<!-- <template v-slot:item.name="{ item }">
							{{ item.Messages[0].Student.Account.FullName }}
						</template> -->

						<!-- format the date -->
						<template v-slot:item.SentAt="{ item }">
							{{ dateFormaterJustDate(new Date(item.Messages[0].SentAt)) }}
						</template>

						<!-- slice the message -->
						<template v-slot:item.Content="{ item }">
							{{ item.Messages[0].Content?.slice(0, 70) }}
							<span v-if="item.Messages[0].Content?.length > 70"> ... </span>
						</template>

						<!-- button for unread messages -->
						<template v-slot:item.IsOpened="{ item }">
							<!-- button to make it read -->
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										v-if="unReadMessages(item).length"
										:disabled="!!loadingMarkAsRead"
										:loading="loadingMarkAsRead === item.StudentId"
										color="myOrangeBg"
										@click.stop="markAsRead(unReadMessages(item))"
										x-small
										fab
										elevation="0"
										v-bind="attrs"
										v-on="on">
										<v-icon
											small
											color="myOrange">
											mdi-eye-remove-outline
										</v-icon>
									</v-btn>
									<v-icon
										v-else
										class="pa-2"
										small
										color="myGreen"
										v-bind="attrs"
										v-on="on">
										mdi-eye-check-outline
									</v-icon>
								</template>
								<span v-if="unReadMessages(item).length"> تحديد كمقروء </span>
								<span v-else>مقروءة</span>
							</v-tooltip>
						</template>
					</v-data-table>

					<v-data-table
						v-else
						:headers="studentsByNameHeader"
						:items="[
							...studentsSearchedByName.StudentsWithMessages.map((e) => ({ ...e, old: true })),
							...studentsSearchedByName.StudentsWithOutMessages,
						]"
						:loading="loadingSearchStudent"
						:loading-text="'انتظر قليلا...'"
						:no-data-text="unReadOnly ? 'لاتوجد رسائل غير مقروءة' : 'لاتوجد رسائل'"
						:items-per-page="-1"
						hide-default-footer
						mobile-breakpoint="0"
						fixed-header
						height="calc(100vh - 180px)">
						<template v-slot:item="{ item }">
							<tr
								v-if="item.old"
								@click="openMessageFromName(item)">
								<td class="font-weight-black">
									{{ item.Account.FullName }}
								</td>
								<td class="font-weight-black">
									{{ item.Specialty.SpecialtyName }}
								</td>
								<td class="font-weight-black">
									{{ item.Course.CourseName }}
								</td>
							</tr>
							<tr
								v-else
								@click="openMessageFromName(item)">
								<td>
									{{ item.Account.FullName }}
								</td>
								<td>
									{{ item.Specialty.SpecialtyName }}
								</td>
								<td>
									{{ item.Course.CourseName }}
								</td>
							</tr>
						</template>
					</v-data-table>
				</v-sheet>
			</div>

			<v-divider
				vertical
				ref="divider"
				class="px-2 mx-2 d-none d-md-block"
				style="cursor: col-resize"></v-divider>

			<!-- chat -->
			<div
				class="page-part mt-4 mt-md-0"
				ref="chat">
				<chat
					v-if="student"
					@refresh="whatMessagesToShow"
					:loading.sync="loadingChat"
					:student="student"
					:courseName="courseName"></chat>
				<div
					v-else
					class="not-selected d-flex align-center justify-center text-h5 text--secondary py-16">
					اختر احد الطلاب
				</div>
			</div>
		</div>

		<!-- start new message -->
		<v-dialog
			v-model="dialog"
			width="800">
			<v-card class="pa-5">
				<h2 class="primary--text mb-5">بدء محادثة</h2>
				<!-- filter form  -->
				<v-row
					class="ma-0"
					align="center"
					dense>
					<v-col
						cols="6"
						sm="4"
						md="auto">
						<v-select
							v-model="selectedSpecialtyId"
							@click:clear="
								selectedSpecialtyId = null;
								selectedCourseId = null;
							"
							:items="specialties"
							item-text="SpecialtyName"
							item-value="SpecialtyId"
							label="الصف"
							outlined
							dense
							hide-details
							clearable
							:menu-props="{ offsetY: true }"
							class="rounded-lg"></v-select>
					</v-col>
					<v-col
						cols="6"
						sm="4"
						md="auto">
						<v-select
							v-model="selectedCourseId"
							@click:clear="selectedCourseId = null"
							:disabled="!selectedSpecialtyId"
							:items="specialties.find((e) => e.SpecialtyId === selectedSpecialtyId)?.Courses"
							item-text="CourseName"
							item-value="Id"
							label="الشعبة"
							outlined
							dense
							hide-details
							clearable
							:menu-props="{ offsetY: true }"
							class="rounded-lg"></v-select>
					</v-col>
					<v-col
						cols="12"
						sm="4"
						md="auto">
						<v-text-field
							autofocus
							v-model="searchName"
							label="اسم الطالب"
							outlined
							dense
							hide-details
							append-icon="mdi-magnify"
							class="rounded-lg"
							@keydown.enter="search"></v-text-field>
					</v-col>
					<v-col cols="auto">
						<v-btn
							:disabled="
								loadingStudents ||
								(!selectedSpecialtyId && !searchName) ||
								(selectedSpecialtyId && !selectedCourseId)
							"
							:loading="loadingStudents"
							color="primary"
							@click="search">
							بحث
						</v-btn>
					</v-col>
				</v-row>

				<!-- student table -->
				<v-sheet
					class="mt-5"
					height="50vh">
					<!-- for no data -->
					<div
						class="py-10 text-center text-h5 text--secondary"
						v-if="!students.length">
						{{ isSearched ? 'لا يوجد طلاب في هذه الشعبة' : 'ابحث عن طالب' }}
					</div>

					<!-- student table -->
					<v-row
						v-else
						dense>
						<v-col
							cols="12"
							sm="6"
							md="4"
							v-for="studentIndex in students"
							:key="studentIndex.Id">
							<v-card
								elevation="1"
								class="pa-1 text-center"
								@click="openMessageFromDialog(studentIndex)">
								{{ studentIndex.Account.FirstName }}
								{{ studentIndex.Account.LastName }}
							</v-card>
						</v-col>
					</v-row>
				</v-sheet>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import Chat from '@/components/Chat.vue';
import { mapState } from 'vuex';
import { dateFormaterJustDate } from '@/helpers/helpers';

export default {
	name: 'Home',

	components: { Chat },

	data() {
		return {
			//for all
			loadingOldMessages: false,
			loadingMarkAsRead: null,
			loadingChat: false,
			loadingSearchStudent: false,

			dialog: false,

			//for old messages
			unReadOnly: true,
			oldMessagesHeader: [
				{
					text: 'اسم الطالب',
					value: 'FullName',
				},
				{
					text: 'الصف - الشعبة',
					value: 'CourseName',
					filterable: false,
				},
				{
					text: 'التاريخ',
					value: 'SentAt',
					filterable: false,
				},
				{
					text: 'الرسالة',
					value: 'Content',
					sortable: false,
				},
				{
					text: 'مقروء',
					value: 'IsOpened',
					sortable: false,
					filterable: false,
				},
			],

			//for one message
			student: null,
			courseName: null,

			// for search by name
			searchStudentName: '',
			showSearchedNameTable: false,
			studentsSearchedByName: [],
			studentsByNameHeader: [
				{
					text: 'اسم الطالب',
					value: 'Account.FullName',
					filterable: false,
				},
				{
					text: 'الصف',
					value: 'Specialty.SpecialtyName',
					filterable: false,
				},
				{
					text: 'الشعبة',
					value: 'Course.CourseName',
					filterable: false,
				},
			],

			selectedSpecialtyId: null,
			selectedCourseId: null,
			searchName: null,
			loadingStudents: false,
			students: [],
			isSearched: false,
		};
	},

	computed: {
		studentsSearchedByNameFiltered() {},
		...mapState({
			specialties: (state) => state.message.specialties,
			oldMessages: (state) => state.message.oldMessages,
		}),
	},

	methods: {
		// for all
		dateFormaterJustDate,
		initDragging() {
			if (!this.$refs.divider) return;
			let isDragging = false;
			this.$refs.divider.$el.addEventListener('mousedown', (e) => {
				isDragging = true;
				this.$refs.divider.$el.style.borderColor = '#0f4d96';
			});
			this.$refs.container.addEventListener('mousemove', (e) => {
				if (!isDragging) return;
				const containerRect = this.$refs.container.getBoundingClientRect();
				const x = e.clientX - containerRect.left;
				const containerWidth = containerRect.width;
				const draggableWidth = this.$refs.divider.$el.offsetWidth;
				const leftCol = this.$refs.chat;
				const rightCol = this.$refs.filters;
				const leftColWidth = x - draggableWidth / 2;
				const rightColWidth = containerWidth - x - draggableWidth / 2;
				leftCol.style.width = `${leftColWidth}px`;
				rightCol.style.width = `${rightColWidth}px`;
			});
			this.$refs.container.addEventListener('mouseup', (e) => {
				isDragging = false;
				this.$refs.divider.$el.style.borderColor = 'rgba(0, 0, 0, 0.12)';
			});
		},

		// for one message
		markAsRead(message) {
			this.loadingMarkAsRead = message[0].StudentId;
			this.$store
				.dispatch('message/markAsRead', { ids: message.map((e) => e.Id) })
				.then(() => {
					this.loadingOldMessages = true;
					this.$store
						.dispatch('message/fetchOldMessages', {
							unReadOnly: this.unReadOnly,
							pageNumber: 1,
						})
						.then(() => {
							this.$eventBus.$emit('show-snackbar', false, 'تم التحديد كمقروء');
							this.refresh();
						})
						.catch(() => {})
						.finally(() => {
							this.loadingOldMessages = false;
						});
				})
				.catch(() => {})
				.finally(() => {
					this.loadingMarkAsRead = null;
				});
		},
		whatMessagesToShow() {
			this.loadingOldMessages = true;
			this.$store
				.dispatch('message/fetchOldMessages', {
					unReadOnly: this.unReadOnly,
					pageNumber: 1,
				})
				.then(() => {})
				.catch(() => {})
				.finally(() => {
					this.loadingOldMessages = false;
				});
		},
		openMessageFromName(item) {
			this.student = {
				Id: item.StudentId,
				UserName: item.UserName,
				Name: item.StudentName,
			};
			this.courseName = item.Course.CourseName;
			this.fetchMessages();
		},
		openMessageFromOld(item) {
			if (this.loadingMarkAsRead) return;
			this.student = {
				Id: item.StudentId,
				UserName: item.UserName,
				Name: item.FullName,
			};
			this.courseName = item.CourseName;
			this.fetchMessages();
		},
		openMessageFromDialog(item) {
			this.dialog = false;
			this.student = {
				Id: item.StudentId || item.Id,
				UserName: item.UserName,
				Name: item.StudentName || item.Account.FullName,
			};
			this.courseName = this.selectedCourseId
				? this.specialties
						.find((e) => e.SpecialtyId === this.selectedSpecialtyId)
						?.Courses.find((e) => e.Id === this.selectedCourseId)?.CourseName
				: item.Course.CourseName;
			this.fetchMessages();
		},
		fetchMessages() {
			this.loadingChat = true;
			this.$store
				.dispatch('message/fetchMessages', {
					studentId: this.student.Id || this.student.StudentId,
				})
				.then(() => {})
				.catch(() => {})
				.finally(() => {
					this.loadingChat = false;
				});
		},
		studentTableClass(item) {
			if (item.StudentId === this.student?.Id) return 'myBlue--text';
		},
		unReadMessages(item) {
			return item.Messages.filter((e) => !e.IsOpened);
		},

		searchStudentByName() {
			if (!this.searchStudentName) {
				this.showSearchedNameTable = false;
				return;
			}
			this.loadingSearchStudent = true;
			this.$store
				.dispatch('message/fetchStudentByName', { studentName: this.searchStudentName })
				.then((res) => {
					this.studentsSearchedByName = res;
					this.showSearchedNameTable = true;
				})
				.finally(() => {
					this.loadingSearchStudent = false;
				});
		},

		// for search
		search() {
			if (this.selectedCourseId) this.fetchStudentByCourse();
			else if (this.searchName) this.fetchStudentsByName();
			else {
				this.selectedCourseId = null;
				this.selectedSpecialtyId = null;
				this.searchName = null;
				this.searched = false;
			}
		},
		fetchStudentByCourse() {
			this.loadingStudents = true;
			this.$store
				.dispatch('message/fetchStudents', {
					courseId: this.selectedCourseId,
				})
				.then((res) => {
					this.students = res;
					this.isSearched = true;
					if (this.searchName) {
						this.students = this.students.filter((e) => {
							return (
								e.Account.FirstName.includes(this.searchName) ||
								e.Account.LastName.includes(this.searchName)
							);
						});
					}
				})
				.finally(() => {
					this.loadingStudents = false;
				});
		},
		fetchStudentsByName() {
			this.loadingStudents = true;
			this.$store
				.dispatch('message/fetchStudentByName', {
					studentName: this.searchName,
				})
				.then((res) => {
					this.isSearched = true;
					this.students = [...res.StudentsWithMessages, ...res.StudentsWithOutMessages];
				})
				.finally(() => {
					this.loadingStudents = false;
				});
		},

		refresh() {
			this.whatMessagesToShow();
			if (this.student) this.fetchMessages();
		},
	},

	created() {
		window.addEventListener('keydown', (e) => {
			if (e.altKey && e.code === 'KeyN') {
				e.preventDefault();
				this.dialog = true;
			}
		});
	},

	mounted() {
		this.initDragging();
	},
};
</script>
