import Vue from 'vue'
import Vuex from 'vuex'
import login from './modules/login'
import message from './modules/message'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    clearAfterLogout({ dispatch }) {
      dispatch('message/clear')
    }
  },
  modules: {
    login,
    message
  }
})
